<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :append-to-body="true"
    :visible.sync="visible"
    width="40%"
  >
    <el-form ref="client" :model="client" :rules="formRules">
      <el-form-item label="Фамилия Имя Отчество" prop="name">
        <el-input v-model="client.name" placeholder="Иванов Иван Иванович" autocomplete="off" />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Email" prop="email">
            <el-input v-model="client.email" placeholder="example@example.com" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Мессенджер" prop="messenger">
            <el-select v-model="client.messenger" style="width: 100%" placeholder="Viber">
              <el-option label="WhatsApp" value="WhatsApp" />
              <el-option label="Instagram" value="Instagram" />
              <el-option label="Vk" value="Vk" />
              <el-option label="Telegram" value="Telegram" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="client.messenger" label="Никнейм" prop="messenger_login">
            <el-input v-model="client.messenger_login" placeholder="ivan1234" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Телефон" prop="phone">
            <div class="el-input">
              <masked-input
                v-model="client.phone"
                mask="\+7 111 111-1111"
                placeholder="8 929 003 12 34"
                class="el-input__inner"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Дата создания" prop="created_at">
            <el-date-picker
              v-model="client.created_at"
              type="date"
              :picker-options="{ firstDayOfWeek: 1 }"
              placeholder="Дата создания"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import MaskedInput from 'vue-masked-input';
import { notifyMixin } from '../shared/mixins/notify-mixin';
import { STORE_CLIENT } from '../shared/state/types/action_types';

export default {
  name: 'ClientCreateModal',
  components: { MaskedInput },
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить клиента',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      client: {},
      formRules: {
        name: [{ required: true, message: 'Введите ФИО', trigger: 'blur' }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  methods: {
    ...mapActions({
      createClient: STORE_CLIENT,
    }),
    saveFn() {
      this.$refs.client.validate(async (valid) => {
        if (valid) {
          await this.createClient(this.client);
          this.showSuccessMessage();
          this.visible = false;
          return true;
        }
        return false;
      });
    },
  },
};
</script>
