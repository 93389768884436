<template>
  <div class="clients-table mt-4">
    <app-table
      :loading="loading"
      :data="clients ? clients : []"
      :fields="fields"
      :rules="tableRules"
      @reload="reloadClients"
      @goto="editClient"
      @delete="deleteClient"
    />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapState } from 'vuex';
import { FETCH_CLIENTS, DELETE_CLIENT } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'ClientsTable',
  components: { AppTable },
  mixins: [notifyMixin],
  data() {
    return {
      fields: [
        { label: '#', value: 'id' },
        { label: 'Название', value: 'name', width: 150 },
        { label: 'Телефон', value: 'phone', width: 150 },
        { label: 'Мессенджер', value: 'messenger' },
        { label: 'Никнейм', value: 'messenger_login' },
        { label: 'Тип', value: 'type.title' },
        { label: 'Район', value: 'district' },
        { label: 'Дата создания', value: 'created_at_formatted' },
        { label: 'Филиал', value: 'branch.title' },
      ],
      tableRules: [
        {
          value: 'goto',
          type: 'Info',
          icon: 'el-icon-folder-opened',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      loading: false,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      clients: (state) => state.clientsState.clientsData.clients,
    }),
  },
  watch: {
    async $route(to) {
      await this.fetchClients({ page: this.page, filter: to.query });
    },
  },
  async created() {
    const queryParams = this.$route.query;
    await this.fetchClients({ filter: { ...queryParams } });
  },
  methods: {
    ...mapActions({
      fetchClients: FETCH_CLIENTS,
      delete: DELETE_CLIENT,
    }),
    async reloadClients(page) {
      this.page = page;
      await this.fetchClients({ page, filter: this.$route.query });
    },
    deleteClient(client) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(client.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Клиент успешно удален');
        })
        .catch(() => {});
    },
    editClient(client) {
      this.$router.push(`client/${client.id}/info`);
    },
  },
};
</script>
