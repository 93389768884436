<template>
  <div>
    <clients-filter @open-create-modal="openModal" />
    <clients-table />
    <client-create-modal :show.sync="showAddClient" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientsFilter from './clients-filter/clients-filter.vue';
import ClientsTable from './clients-table/clients-table.vue';
import ClientCreateModal from './client-modals/client-create.vue';
import { RESET_CLIENTS } from './shared/state/types/action_types';

export default {
  name: 'Clients',
  components: { ClientsTable, ClientsFilter, ClientCreateModal },
  data() {
    return {
      title: 'Добавить клиента',
      name: null,
      phone: null,
      messenger: null,
      messenger_login: null,
      client: {
        name: null,
        email: null,
        phone: null,
        messenger: null,
        messenger_login: null,
      },
      loading: true,
      showAddClient: false,
      fields: [
        { label: '#', value: 'id' },
        { label: 'Название', value: 'name' },
        { label: 'Телефон', value: 'phone' },
      ],
      rules: [
        {
          value: 'goto',
          type: 'Info',
          icon: 'el-icon-folder-opened',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      formRules: {
        name: [{ required: true, message: 'Введите ФИО', trigger: 'blur' }],
        email: [{ required: true, message: 'Введите Email', trigger: 'blur' }],
        phone: [{ required: true, message: 'Введите телефон', trigger: 'blur' }],
        messenger: [{ required: true, message: 'Введите мессенджер', trigger: 'blur' }],
        messenger_login: [{ required: true, message: 'Введите никнейм', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters({ clients: 'CLIENTS' }),
  },
  destroyed() {
    this.$store.dispatch(RESET_CLIENTS);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.sendDataClient();
          return true;
        }
        return false;
      });
    },
    handleClose() {
      this.showAddClient = false;
    },
    openModal() {
      this.showAddClient = true;
    },
    goToClient(client) {
      this.$router.push(`client/${client.id}/info`);
    },
    handleDelete(client) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_CLIENT', client.id);
            this.$notify({
              title: 'Успешно',
              message: 'Клиент успешно удален',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
          this.loading = false;
        })
        .catch(() => {});
    },
    async sendDataClient() {
      this.loading = true;
      try {
        await this.$store.dispatch('STORE_CLIENT', this.client);
        this.$notify({
          title: 'Успешно',
          message: 'Клиент успешно добавлен',
          duration: 1500,
          type: 'success',
        });
        this.loading = false;
      } catch (e) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка',
          duration: 1500,
        });
        this.loading = false;
      }
      this.handleClose();
      return true;
    },
  },
};
</script>
