<template>
  <div class="clients-filter">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-badge is-dot :hidden="isActiveFilter" class="item">
          <el-button icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
        </el-badge>
      </el-col>
      <el-col :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="primary" icon="el-icon-plus" @click="openModal">
            Добавить клиента
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div v-show="isShowFilter" class="filter-panel mt-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="name" prefix-icon="el-icon-search" placeholder="Поиск по имени" />
        </el-col>
        <el-col :span="6">
          <masked-input
            v-model="phone"
            mask="\+7 111 111-1111"
            placeholder="Поиск по номеру телефона"
            class="el-input__inner"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="messenger"
            prefix-icon="el-icon-search"
            placeholder="Поиск по месенджеру"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="messenger_login"
            prefix-icon="el-icon-search"
            placeholder="Поиск по никнейму"
          />
        </el-col>
        <el-col :span="6">
          <el-autocomplete
            v-model="branch"
            style="width: 100%"
            :fetch-suggestions="searchBranchFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск по филиалу"
            value-key="title"
            class="mt-2"
            clearable
            select-when-unmatched
            @select="selectBranch"
            @change="changeBranch"
          />
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="type"
            filterable
            placeholder="Поиск по типу клиента"
            class="mt-2"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in clientTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="district"
            style="width: 100%"
            filterable
            clearable
            placeholder="Поиск оп району"
            class="mt-2 w-100"
          >
            <el-option
              v-for="item in districts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-date-picker
            v-model="created_at"
            class="mt-2 w-100"
            type="daterange"
            range-separator="-"
            :picker-options="{ firstDayOfWeek: 1 }"
            start-placeholder="От"
            end-placeholder="До"
            format="dd MMMM yyyy"
            value-format="yyyy-MM-dd"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_BRANCHES } from '@/app/settings/branches/shared/state/types/action_types';
import { DATA as DATA_BRANCHES } from '@/app/settings/branches/shared/state/types/getter_types';
import store from '@/app/app-state';
import { computed } from '@vue/composition-api';
import {
  CLIENT_TYPE_STORE_MODULE_NAME,
  clientTypesStoreModule,
} from '@/app/settings/client-types/clientTypesStoreModule';
import {
  DISTRICT_STORE_MODULE_NAME,
  districtsStoreModule,
} from '@/app/settings/districts/districtsStoreModule';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'ClientsFilter',
  components: { MaskedInput },
  data() {
    return {
      isShowFilter: false,
      isActiveFilter: true,
      name: '',
      query: {},
      phone: null,
      branch_id: null,
      messenger_login: null,
      messenger: null,
      branch: '',
      type: '',
      district: null,
      created_at: null,
    };
  },
  computed: {
    ...mapGetters({ branches: DATA_BRANCHES }),
  },
  watch: {
    query(query) {
      if (query.name === '') {
        delete query.name;
      }
      if (query.phone === '') {
        delete query.phone;
      }
      if (query['branch.title'] === '') {
        delete query['branch.title'];
      }
      if (query.messenger_login === '') {
        delete query.messenger_login;
      }
      if (query.messenger === '') {
        delete query.messenger;
      }
      this.$router.push({ path: '/clients', query }).catch(() => {});
    },
    name(value) {
      this.query = { ...this.query, name: value };
    },
    phone(value) {
      this.query = { ...this.query, phone: value };
    },
    branch(value) {
      this.query = { ...this.query, 'branch.title': value };
    },
    messenger(value) {
      this.query = { ...this.query, messenger: value };
    },
    messenger_login(value) {
      this.query = { ...this.query, messenger_login: value };
    },
    type(value) {
      this.query = { ...this.query, type_id: value || undefined };
    },
    district(value) {
      this.query = { ...this.query, 'address.district': value || undefined };
    },
    created_at(value) {
      this.query = { ...this.query, created_at: value ? value.join(',') : undefined };
    },
  },
  created() {
    const queryParams = this.$route.query;
    this.filter = queryParams;
  },
  methods: {
    ...mapActions({ fetchBranches: FETCH_BRANCHES }),
    selectBranch(item) {
      this.branch_id = item.id;
    },
    openFilter() {
      this.isShowFilter = !this.isShowFilter;
      this.isActiveFilter = !this.isActiveFilter;
    },
    changeBranch(value) {
      if (!value) {
        delete this.query.branch_id;
      }
    },
    async searchBranchFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchBranches(filter);
      cb(this.branches.data);
    },
    openModal() {
      this.$emit('open-create-modal', true);
    },
  },
  setup() {
    if (!store.hasModule(CLIENT_TYPE_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_TYPE_STORE_MODULE_NAME, clientTypesStoreModule);
    }
    const fetchClientTypes = (params) => {
      store.dispatch(`${CLIENT_TYPE_STORE_MODULE_NAME}/fetchClientTypes`, params);
    };
    fetchClientTypes();
    const clientTypes = computed(
      () => store.getters[`${CLIENT_TYPE_STORE_MODULE_NAME}/autocompleteClientTypes`]
    );
    if (!store.hasModule(DISTRICT_STORE_MODULE_NAME)) {
      store.registerModule(DISTRICT_STORE_MODULE_NAME, districtsStoreModule);
    }
    const fetchDistrict = (params) => {
      store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/fetchDistricts`, params);
    };
    fetchDistrict();
    const districts = computed(
      () => store.getters[`${DISTRICT_STORE_MODULE_NAME}/autocompleteDistricts`]
    );
    return { clientTypes, districts };
  },
};
</script>
